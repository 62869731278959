/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql, withPrefix} from 'gatsby'
import {getFirstParagraph, getSpecifiedCountryUrl} from '../../utils'

function SEO({
  description,
  htmldescription,
  lang,
  meta,
  keywords,
  title,
  imageSrc,
  location,
  product,
  seo,
}) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            title
            siteUrl
          }
        }
      }
    `,
  )

  // Format SEO title
  let seoTitle = title
  if (!seoTitle && seo && seo.title != '| Kooi Beds') {
    seoTitle = site.siteMetadata.name
  }
  if (!seoTitle) {
    seoTitle = 'Kooi Beds'
  }
  seoTitle = `${seoTitle} | ${site.siteMetadata.title} `

  // Format SEO description
  let seoDescription =
    description || (seo && seo.metaDesc) || site.siteMetadata.description
  if (htmldescription) {
    seoDescription = getFirstParagraph(htmldescription)
  }

  // Format keywords
  const seoKeywords = keywords || (seo && seo.metaKeywords)

  // Format OG data
  const ogSiteName = (location && location.host) || 'www.kooibeds.com'
  let ogImage =
    imageSrc ||
    (seo && seo.opengraphImage) ||
    `https://www.kooibeds.com/favicons/ms-icon-310x310.png`

  // Main Tags
  const seoData = [
    {
      name: `description`,
      content: seoDescription,
    },
    {name: 'msapplication-TileColor', content: '#da532c'},
    {name: 'theme-color', content: '#ffffff'},
  ]

  // Keywords Tags
  const keywordsData =
    seoKeywords.length > 0
      ? {
          name: `keywords`,
          content: seoKeywords.join(`, `),
        }
      : []

  // Open Graph Tags
  const openGraphData = [
    {
      property: `og:title`,
      content: (seo && seo.opengraphTitle) || seoTitle,
    },
    {
      property: `og:description`,
      content: (seo && seo.opengraphDescription) || seoDescription,
    },
    {
      property: `og:image`,
      content: ogImage,
    },
    {
      property: `og:site_name`,
      content: ogSiteName,
    },
    {
      property: `og:type`,
      content: product ? 'product' : `website`,
    },
  ]

  // Open Graph Product
  const openGraphProduct = product
    ? [
        {
          property: `product:price:amount`,
          content: product.amount,
        },
        {
          property: `product:price:currency`,
          content: product.currency,
        },
      ]
    : []

  // Twitter Tags
  const twitterData = [
    {
      name: `twitter:card`,
      content: `summary`,
    },
    // {
    //   name: `twitter:creator`,
    //   content: site.siteMetadata.author,
    // },
    {
      name: `twitter:title`,
      content: seo && seo.twitterTitle,
    },
    {
      name: `twitter:description`,
      content: seo && seo.twitterDescription,
    },
  ]

  // Build all meta tags into one array
  const metaTags = []
    .concat(seoData)
    .concat(keywordsData)
    .concat(openGraphData)
    .concat(openGraphProduct)
    .concat(twitterData)
    .concat(meta)

  // console.log('MET', metaTags)

  // WordPress pages have an SEO title that is formatted as follows: "*page title* | Kooi Beds"
  // So the titleTemplate needs to mimic this (for non-WP pages)

  const saUrl = getSpecifiedCountryUrl(location.pathname, 'za')
  const nzUrl = getSpecifiedCountryUrl(location.pathname, 'nz')

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seoTitle}
      meta={metaTags}
    >
      {saUrl && <link rel="alternate" hreflang="en-za" href={saUrl} />}
      {nzUrl && <link rel="alternate" hreflang="en-nz" href={nzUrl} />}
      {saUrl && <link rel="alternate" hreflang="en" href={saUrl} />}
      {saUrl && <link rel="alternate" hreflang="x-default" href={saUrl} />}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={withPrefix('/favicons/apple-icon-180x180.png')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={withPrefix('/favicons/favicon-32x32.png')}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={withPrefix('/favicons/favicon-16x16.png')}
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  title: '',
  image: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  image: PropTypes.string,
  seo: PropTypes.objectOf(PropTypes.string),
}

export default SEO
