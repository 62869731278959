import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Checked from '../../images/icons/green-tick.svg'
import Cross from '../../images/icons/red-cross.svg'
import MobileTableNew from './MobileTableNew'
import {formatMoney} from '../../utils'

export default function ComparisonTableNew({pageSlug}) {
  const data = useStaticQuery(graphql`
    {
      allProducts: allWcProducts {
        nodes {
          slug
          name
          price
        }
      }

      comparisons: allWordpressWpMattress {
        nodes {
          slug
          title
          acf {
            column_one_header {
              image {
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 400
                      quality: 80
                      srcSetBreakpoints: [100]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              product_url_slug
            }
            column_two_header {
              image {
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 400
                      quality: 80
                      srcSetBreakpoints: [100]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              product_url_slug
            }
            column_three_header {
              image {
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 400
                      quality: 80
                      srcSetBreakpoints: [100]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              product_url_slug
            }
            text_features {
              feature
              column_1
              column_2
              column_3
            }
            checkbox_features {
              feature
              column_1
              column_2
              column_3
            }
          }
        }
      }
    }
  `)

  const {allProducts} = data

  const allMattresses = data.comparisons
  var chosenMattress = allMattresses.nodes.filter(function(mat) {
    return mat.slug == pageSlug
  })
  chosenMattress = chosenMattress[0] // There should only be one anyway.

  const {
    column_one_header,
    column_two_header,
    column_three_header,
    checkbox_features,
    text_features,
  } = chosenMattress.acf
  const columnOneImageFile =
    column_one_header.image &&
    column_one_header.image.localFile &&
    column_one_header.image.localFile.childImageSharp.fluid
  const columnTwoImageFile =
    column_two_header.image &&
    column_one_header.image.localFile &&
    column_one_header.image.localFile.childImageSharp.fluid
  const columnThreeImageFile =
    column_three_header.image &&
    column_one_header.image.localFile &&
    column_one_header.image.localFile.childImageSharp.fluid

  let mobile_features = text_features.concat(checkbox_features)

  var column_one_product = allProducts.nodes.filter(function(prd) {
    return prd.slug == column_one_header.product_url_slug
  })
  column_one_product = column_one_product[0]

  var column_two_product = allProducts.nodes.filter(function(prd) {
    return prd.slug == column_two_header.product_url_slug
  })
  column_two_product = column_two_product[0]

  var column_three_product = allProducts.nodes.filter(function(prd) {
    return prd.slug == column_three_header.product_url_slug
  })
  column_three_product = column_three_product[0]

  return (
    <Container>
      <VisibleMobile>
        <MobileTableNew
          title={column_one_product.name
            .replace('Kooi', '')
            .replace('Mattress', '')}
          price={formatMoney(column_one_product.price)}
          rows={mobile_features}
          filter="column_1"
          img={columnOneImageFile}
          to={'/product/' + column_one_product.slug + '/'}
        />
        <MobileTableNew
          title={column_two_product.name
            .replace('Kooi', '')
            .replace('Mattress', '')}
          price={formatMoney(column_two_product.price)}
          rows={mobile_features}
          filter="column_2"
          img={columnTwoImageFile}
          to={'/product/' + column_two_product.slug + '/'}
        />
        <MobileTableNew
          title={column_three_product.name
            .replace('Kooi', '')
            .replace('Mattress', '')}
          price={formatMoney(column_three_product.price)}
          rows={mobile_features}
          filter="column_3"
          img={columnThreeImageFile}
          to={'/product/' + column_three_product.slug + '/'}
        />
      </VisibleMobile>
      <Table>
        <thead>
          <tr>
            <th />
            <th width="20%">
              {columnOneImageFile && <Img fluid={columnOneImageFile} />}
              <h3>
                {column_one_product.name
                  .replace('Kooi', '')
                  .replace('Mattress', '')}
              </h3>
            </th>
            <th width="20%">
              {columnTwoImageFile && <Img fluid={columnTwoImageFile} />}
              <h3>
                {column_two_product.name
                  .replace('Kooi', '')
                  .replace('Mattress', '')}
              </h3>
            </th>
            <th width="20%">
              {columnThreeImageFile && <Img fluid={columnThreeImageFile} />}
              <h3>
                {column_three_product.name
                  .replace('Kooi', '')
                  .replace('Mattress', '')}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {text_features.map(text_feature => (
            <tr>
              <td>{text_feature.feature}</td>
              <td>{text_feature.column_1}</td>
              <td>{text_feature.column_2}</td>
              <td>{text_feature.column_3}</td>
            </tr>
          ))}
          {checkbox_features.map(checkbox_feature => (
            <tr>
              <td>{checkbox_feature.feature}</td>
              <td>
                <img
                  src={checkbox_feature.column_1 ? Checked : Cross}
                  alt="checked"
                />
              </td>
              <td>
                <img
                  src={checkbox_feature.column_2 ? Checked : Cross}
                  alt="checked"
                />
              </td>
              <td>
                <img
                  src={checkbox_feature.column_3 ? Checked : Cross}
                  alt="checked"
                />
              </td>
            </tr>
          ))}
          <tr className="price-row">
            <td>Mattress Price</td>
            <td>{formatMoney(column_one_product.price)}</td>
            <td>{formatMoney(column_two_product.price)}</td>
            <td>{formatMoney(column_three_product.price)}</td>
          </tr>
          <tr className="button-row">
            <td />
            <td>
              <WhiteButton to={'/product/' + column_one_product.slug + '/'}>
                View{' '}
                {column_one_product.name
                  .replace('Kooi', '')
                  .replace('Mattress', '')}
              </WhiteButton>
            </td>
            <td>
              <WhiteButton to={'/product/' + column_two_product.slug + '/'}>
                View{' '}
                {column_two_product.name
                  .replace('Kooi', '')
                  .replace('Mattress', '')}
              </WhiteButton>
            </td>
            <td>
              <WhiteButton to={'/product/' + column_three_product.slug + '/'}>
                View{' '}
                {column_three_product.name
                  .replace('Kooi', '')
                  .replace('Mattress', '')}
              </WhiteButton>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

const Container = styled.div`
  padding: 80px 0;
  h2.red {
    margin-bottom: 110px !important;
  }
`

const VisibleMobile = styled.div`
  display: block;
  @media (min-width: 900px) {
    display: none;
  }
`
const WhiteButton = styled(Link)`
  border: 2px solid ${({theme}) => theme.grey2};
  border-radius: 200px;
  color: ${({theme}) => theme.grey2};
  background: #fff;
  padding: 5px 20px;
  margin: 5px 0;
  display: inline-block;
  transition: 0.2s all ease-in-out;
  &:hover {
    color: #fff;
    background: ${({theme}) => theme.red};
    border: 2px solid ${({theme}) => theme.red};
  }
`
const Table = styled.table`
  @media (max-width: 900px) {
    display: none;
  }
  th img {
    padding: 20px;
  }
  width: 100%;
  .price-row td {
    background: ${props => props.theme.dark} !important;
    color: ${props => props.theme.white};
    height: 80px;
    font-weight: 600;
    font-size: 23px;
  }
  .button-row td {
    background: #fff !important;
  }
  thead {
    th {
      padding-bottom: 60px;
      &:first-child {
        text-align: left;
        width: 30%;
        padding-right: 40px;
        h3 {
          font-size: 24px;
        }
        p {
          font-weight: 100;
        }
      }
    }
  }
  tbody {
    tr td:first-child {
      background: ${({theme}) => theme.grey1};
      color: white;
      text-align: left;
      font-size: 20px;
      padding-left: 20px;
    }
    tr:nth-child(even) {
      background: ${({theme}) => theme.grey4};

      td:first-child {
        background: ${({theme}) => theme.grey2};
      }
    }
    tr:nth-child(odd) {
      background: ${({theme}) => theme.grey3};
    }
    td {
      text-transform: capitalize;
      text-align: center;
      padding: 10px;
      line-height: 23px;
    }
    img {
      height: 30px;
    }
    tr:last-child td {
      font-size: 18px;
    }
  }
`
